<template>
  <div class="settings">
    <home-header></home-header>
    <div class="content">
      <div class="header">
        {{$t('main.settings')}}
      </div>
      <div class="divider"></div>
      <div class="deleteAccount" @click="deleteHandle">{{$t('main.delete_account')}}</div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import Header from '@/components/Header.vue'
  import { userDelete } from '@/api/auth'
  import { MessageBox } from 'element-ui'
  
  export default {
    inject: ['signIn'],
    components: {
      'home-header': Header
    },
    computed: {
      ...Vuex.mapGetters(['userInfo'])
    },
    methods: {
      deleteHandle() {
        if (this.userInfo) {
          MessageBox.confirm(this.$t('main.delete_account_prompt'), {
            confirmButtonText: this.$t('main.delete_account'),
            cancelButtonText: this.$t('main.keep_account'),
            center: true
          }).then(async () => {
            try {
              await userDelete(this.userInfo)
            } finally {
              await this.$store.dispatch('logout')
            }
          })
        } else {
          this.signIn()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .settings {
    background: #F3F4F8;
    min-height: calc(100vh - 14px);
    padding-top: 14px;
    .content {
      margin: 80px auto 0;
      padding: 40px;
      max-width: 1000px;
      background: #FFFFFF;
      border-radius: 12px;
      .header {
        font-size: 30px;
        font-weight: bold;
      }
      .divider {
        width: 100%;
        height: 1px;
        background: #ccc;
        margin: 20px 0 40px;
      }
      .deleteAccount {
        display: inline-block;
        font-size: 12px;
        color: #FF9B00;
        cursor: pointer;
      }
    }
  }
</style>